$spinner-size: 1rem;
$spinner-width: $spinner-size;
$spinner-height: $spinner-size;
$spinner-border-width: 0.2rem;
$at-spinner-sizes: (
  "xs": (
    1rem,
    1rem,
    0.2rem
  ),
  "sm": (
    1.5rem,
    1.5rem,
    0.2rem
  ),
  "md": (
    2rem,
    2rem,
    0.2rem
  ),
  "lg": (
    3rem,
    3rem,
    0.25rem
  )
);

.at-spinner-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 17;
  & > .spinner-border {
    z-index: 20;
  }
}
@mixin spinner-size($width, $height, $border-width) {
  width: $width;
  height: $height;
  border-width: $border-width;
}

.spinner-border {
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  border-color: var(--text-primary-active);
  border-right-color: transparent;
  border-style: solid;
  @each $spinner-size, $sizes in $at-spinner-sizes {
    &-#{$spinner-size} {
      @include spinner-size($sizes...);
    }
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

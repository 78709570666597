@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap");
:root {
  --brand1: #00855d;
  --brand2: #00ab78;
  --brand3: #00d092;
  --brand4: #2ed8a5;
  --brand5: #5ce1b9;

  --bg-primary: #f7f8f8;
  --bg-primary-hover: #eff0f2;
  --bg-primary-active: #e5e7e9;
  --bg-secondary: #eef0f1;
  --text-primary: #eef0f1;
  --text-primary-muted: #c7cbce;
  --text-primary-active: #30363b;
  --text-primary-hover: #7c858b;
  --utility-fade: linear-gradient(180deg, rgba(247, 248, 248, 0) 0%, var(--bg-primary) 100%);
  --utility-input-fade: linear-gradient(270deg, var(--bg-secondary) 0%, rgba(196, 196, 196, 0) 100%);
}
body {
  font-family: "IBM Plex Sans";
  color: var(--text-primary);
  background: #272727;
}
#root {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #272727;
  z-index: 10;
}
.vex-logo {
  width: 200px;
}
.landingInfoContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  box-sizing: border-box;
  padding: 2rem;
  background: #272727cc;
  height: auto;
  min-height: 100%;
  &.enter {
    animation: fadeOut 1s ease-in forwards;
  }
}
.middleArea {
  > * {
    display: block;
    margin: auto;
    margin-bottom: 2rem;
  }
  h1 {
    margin: 0;
    font-weight: 500;
    font-size: 4rem;
    line-height: 4.5rem;
    color: inherit;
  }
  h4 {
    font-weight: 600;
  }
  button {
    padding: 0.5rem 1.5rem;
    border: none;
    background: #c6784c;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 1.5rem;
    color: var(--text-primary-active);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
    &:hover {
      background: var(--brand2);
    }
    &:focus {
      box-shadow: 0 0 0pt 1.5pt rgba(19, 124, 189, 0.6);
      outline: none;
    }
  }
  button.hide {
    visibility: hidden;
    display: none;
    pointer-events: none;
  }
  button.show {
    animation: fadeIn 0.7s forwards;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.placeholder {
  visibility: hidden;
  pointer-events: none;
}
.logoArea {
  height: 2rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
video {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.bgVideo.enter {
  animation: fadeOut 1s ease-in 1s forwards;
}
.bottomArea {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row;

  .vex-logo{
    margin-right: 1rem;
    height: 3.2rem;
    width: auto;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media all and (max-height: 500px), (max-width: 500px) {
  .middleArea h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
@media all and (max-height: 300px) {
  .landingInfoContainer {
    padding: 0;
  }
}
@media all and (max-height: 500px) {
  .bottomArea {
    flex-direction: row;
    .vex-logo{
      margin-right: 1rem;
      height: 2rem;
      width: auto;
    }
  }
  .middleArea > * {
    margin-bottom: 1.5rem;
  }
}
@media all and (max-height: 400px) {
  .middleArea > * {
    margin-bottom: 1rem;
  }
}

#landing-container.fadeOut {
  animation: fadeOut 1.8s ease-in 0.2s forwards;
  pointer-events: none;
}

#unityContainer {
  width: 100vw !important;
  height: 100vh !important;
}

.webgl-content .footer {
  display: none !important;
}

.loading-percent {
  font-size: 0.9rem;
  margin-left: 0.4rem;
}
.iframe-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: all !important;
  &.hide {
    z-index: -1000;
    opacity:0;
    visibility: hidden;
  }
  .spinner-border {
    border-color: var(--text-primary-hover);
    border-right-color: transparent;
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
  }
}
.iframe-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 90%;
  height: 90%;
  max-height: 90vh;
  padding-top: 2rem;
  background: #272727;
  pointer-events: all !important;
  iframe {
    border: 0;
  }
}
.btn-close {
  position: absolute;
  transform: translateX(-100%);
  margin: 0.25rem 0 0 -0.25rem;
  background: transparent;
  margin: 0;
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  position: absolute;
  transform: none;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 0.3rem;
}
.btn-close:hover img {
  width: 2.1rem;
}
.btn-close:hover {
  padding: 0;
}
.config-details h5 {
  font-weight: normal;
  &.desc-paragraph{
    margin-top: 1rem;
  }
}

.config-details {
  max-width: 80vw;
}
.config-details > * {
  margin-bottom: 0;
  margin-top: 0;
  
}
.config-details>.exhibition-desc-head{
  margin: 0.5rem 0;
  font-weight: bold;
}
.config-details br {
  margin-bottom: 1rem;
  width: 100%;
  height: 1px;
}
.modal {
  width: fit-content;
  height: fit-content;
  background: rgba(247, 248, 248, 0.8);
  color: var(--text-primary-active);
  padding: 1rem;
  border-radius: 0.5rem;
}
.btn-primary {
  padding: 0.7rem 1rem;
  color: var(--bg-primary);
  background: var(--text-primary-hover);
  border-radius: 0.25rem;
  border: none;
  font-size: 1rem;
  pointer-events: all;
  cursor: pointer;
  &:hover {
    background: var(--text-primary-active);
  }
}
.button-overlays {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  display: none;
  img {
    padding: 0.5rem;
    background: var(--bg-primary);
    position: absolute;
    pointer-events: all !important;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    &:hover {
      background: var(--bg-primary-hover);
    }
  }
  img.info {
    border-radius: 50%;
    bottom: 1rem;
    right: 1rem;
    opacity: 0;
    transition: opacity 0.4s;
    &.show {
      opacity: 1;
      user-select: none;
    }
  }
  img.reset {
    border-radius: 0.25rem;
    top: 1rem;
    right: 4rem;
  }
  img.fullscreen {
    border-radius: 0.25rem;
    top: 1rem;
    right: 1rem;
  }

  .tutorial-modal {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    > p {
      margin: 0;
      padding-bottom: 0.4rem;
    }
    button {
      margin-top: 0.5rem;
    }
  }
  .info-modal {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-align: right;
    transition: opacity 0.4s;
    opacity: 0;
    display: none;
    &.show {
      opacity: 1;
      display: block;
    }
    > p {
      border-bottom: 2px solid var(--text-primary-hover);
      margin: 0;
      padding: 0.8rem 0;
    }
    > p.last {
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    > p.nospace {
      padding-top: 1rem;
    }
    h3 {
      cursor: pointer;
      pointer-events: all;
    }
  }
}
.designer-profile {
  position: absolute;
  background: white;
  color: black;
  z-index: 5;
  width: 80%;
  max-width: 800px;
  padding: 1rem;
  border-radius: 0.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  overflow-x: hidden;
  > h4 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  > .designer-name {
    font-weight: 500;
    margin-top: 0;
  }
  .btn-close {
    position: sticky;
    margin-left: calc(100% - 1rem);
    margin-top: -2rem;
    transform: translateY(-10px);
  }
}
.designer-image-wrapper {
  width: 20vw;
  border-radius: 0.5rem;
  max-height: 20vw;
  overflow: hidden;
  position: relative;
  > img {
    width: 100%;
  }
}
.designer-description {
  > h3 {
    text-transform: uppercase;
  }
  > p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.designer-name-outer {
  cursor: pointer;
  font-size: 1.5rem;
  color: #c6784c;
  &:hover {
    text-decoration: underline;
  }
}
#g-signin2.hide {
  display: none;
}
.popup {
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-primary);
  text-align: center;
  max-width: 600px;
}
@media all and (max-width: 700px) {
  .popup {
    max-width: 80%;
  }
}
@media all and (max-height: 500px) {
  .popup {
    max-width: 80%;
  }
}
.ytplayerwrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ytplayer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  display: none;
  .fadeOut {
    animation: fadeOut 1s ease-in forwards;
  }
}
.middleArea .btn-secondary {
  background: transparent;
  border: 2px solid #c6784c;
  color: var(--text-primary-muted);
  &:hover {
    background-color: transparent;
    color: var(--text-primary);
  }
}

#btn-enter {
  width: 12rem;
  margin: auto auto 1rem;
  &:hover {
    background: #be6c3c;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}

@media all and (max-width: 700px) {
  .popup {
    max-width: 80%;
  }
}

@media screen and (max-width: 700px), screen and (max-height: 600px) {
  h3.designer-name-outer {
    font-size: 1.1rem;
  }

  .config-details {
    margin-bottom: 0.5rem;
  }

  #btn-enter,
  .btn-secondary {
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.5rem;
    width: 12rem;
    font-size: 0.8rem;
  }
  #g-signin2 {
    top: 69.6% !important;
    border-radius: 1rem;
    overflow: hidden;
    width: fit-content;
  }

  .config-details br {
    display: none;
  }
  .config-details h5 {
    margin-bottom: 0.1rem;
  }
  .logoArea {
    height: 1.5rem;
  }

  .landingInfoContainer {
    padding: 1rem;
  }

  button.btn-secondary {
    margin-bottom: 0.5rem;
  }

  img.vex-logo {
    width: 80px;
    margin-bottom: 0.5rem;
  }
}
#entryvideoElem {
  transition: opacity 0.3s;
}
#landing-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.landingInfoOverflowWrap {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &.enter {
    animation: fadeOut 1s ease-in forwards;
  }
}

//tootip
.__react_component_tooltip {
  font-weight: 500;
}

//title heading
h3.link{
  color: #c6784c;
  text-decoration: underline;
  font-size: 1.5rem;
}